// eslint-disable react-hooks/exhaustive-deps
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import CONST from '@beelineloans/cx-library/src/utils/constants';
import { withPrismicUnpublishedPreview } from 'gatsby-plugin-prismic-previews';
import { NavOnlyTemplate } from '@beelineloans/cx-library/src/components/layout/TemplateV2';
import Error from '@beelineloans/cx-library/src/components/Error';

const NotFound = ({ location }) => {
  const [showError, setShowError] = useState(false);

  // If its a referral url routing will kick in and redirect it. However before routing runs, 404 shows, so stop it showing
  useEffect(() => {
    // only do this redirect on prod, it messes with Prismic Previews on staging
    if (process.env.GATSBY_PROD === 'true' && location.pathname.indexOf('/loan-guides/') > -1) document.location = CONST.LINKS.MAIN.LOAN_GUIDE;
    else if (location.pathname.indexOf('/r/') === -1) setShowError(true);
  }, []);

  return (
    showError
      ? (
        <NavOnlyTemplate>
          <Error code={404} />
        </NavOnlyTemplate>
      )
      : null
  );
};

NotFound.propTypes = {
  location: PropTypes.object,
};

export default process.env.GATSBY_PROD === 'true' ? NotFound : withPrismicUnpublishedPreview(NotFound);
